import { useState } from "react";
import axios from "axios";
import { loaderAction } from "../../actions";
import { connect } from "react-redux";
import { getOtp } from "../utils/utilities";


const Contact = ({ loaderAction }) => {

    const [contactData, setContactData] = useState({ Name: '', RegMob1: '', Email: '', Subject: '', Remarks: '', EncCompanyId: 'W4kjtsE1rUyGzbv6rJqnNw==' });
    const [otp, setOTP] = useState({ isOpen: false, recievedOTP: 'zslfjsd', enteredOTP: '', sent: false, verified: false });

    const handleInputs = (e) => {
        const value = e.target.value;
        const name = e.target.name;             
        setContactData(function(pre) {
            return { ...pre, [name]: value }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(contactData);

        if (contactData.RegMob1.length < 10) return alert('Enter valid phone number.');

        if (!otp.isOpen && !otp.sent) {                        
            loaderAction(true);
            const getOpt = await getOtp(contactData.Name, contactData.RegMob1);
            loaderAction(false);
            if (getOpt) setOTP({...otp, isOpen: true, sent: true, recievedOTP: getOpt});
        } else if (otp.isOpen && otp.sent) {
            if (otp.recievedOTP !== otp.enteredOTP) return alert('Incorrect OTP.');
            setOTP({ ...otp, isOpen: false, verified: true });
        } else if (otp.verified) {
            const reference = await makeContactRequest();
            if (reference) {
                alert(`Request submitted successfully. Please note your reference number ${reference} for future use.`);
                resetForm();
            }
        }
    }

    const resetForm = () => {
        setContactData({ Name: '', RegMob1: '', Email: '', Subject: '', Remarks: '', EncCompanyId: 'W4kjtsE1rUyGzbv6rJqnNw==' });
        setOTP({ isOpen: false, recievedOTP: 'zslfjsd', enteredOTP: '', sent: false, verified: false })
    }

    const makeContactRequest = async () => {
        try {
            loaderAction(true);
            const res = await axios.post(`https://myapps.gsterpsoft.com/api/Appointment`, contactData );
            loaderAction(false);
            if (res.status === 200) {
              return res.data;
            }
            return false;
        } catch (error) {
            alert('An Error Occured, Try again later.');
            loaderAction(false);
            return false;            
        }
    }

    return (
        <section className="contactUs" id="contact">
            <div className="container">
                <form className="contact__page" onSubmit={handleSubmit}>
                    <div className="contact__page__left" data-aos="fade-down" data-aos-offset="400" data-aos-duration='1000'>
                        <h2 className="heading-2">Contact Information</h2>
                        <p>Fill up the form and our Team will get back to you within 24 hours.</p>
                        <ul className="address_list">
                            <li>
                                <i className='bx bxs-phone-call'></i>  +(91) 9674266921 <br/> +(91) 8584057149/51 
                            </li>
                            <li>
                                <i className='bx bxs-envelope'></i> info@viteducare.com
                            </li>
                            <li style={{lineHeight: 2, fontSize: '1.5rem'}}>
                                <i className='bx bxs-map'></i> 96L, S.P. Mukherjee Road, 2nd Floor, Hazra More, Kolkata- 700026 Near Jatin Das Park Metro Station, Beside Pathfinder
                            </li>
                        </ul>
                        <ul className="icon_links" style={{marginTop: '6rem'}}>
                            <li>
                                <i className='bx bxl-facebook'></i>
                            </li>                        
                            <li>
                                <i className='bx bxl-instagram'></i>
                            </li>                        
                            <li>
                                <i className='bx bxl-twitter' ></i>
                            </li>                        
                            <li>
                                <i className='bx bxl-linkedin'></i>
                            </li>
                        </ul>
                    </div>
                    <div className="contact__page__right">
                        <div className="contact__form">
                            <div className="input__fields">
                                <div className="input__group">
                                    <label htmlFor="Name">Your Name</label>
                                    <input onChange={handleInputs} value={contactData.Name} name="Name" type="text" placeholder="Rahul Kumar" id="Name" required/>
                                </div>
                                <div className="input__group">
                                    <label htmlFor="phone">Phone number</label>
                                    <input onChange={handleInputs} value={contactData.RegMob1} name="RegMob1" type="text" placeholder="xxxxxxxxxx" id="phone" required maxLength={10}/>
                                </div>
                                {otp.isOpen && <div className="input__group">
                                    <label htmlFor="otp">Enter your OTP</label>
                                    <input onChange={(e) => setOTP(pre => ({ ...pre, enteredOTP: e.target.value }))} value={otp.enteredOTP} name="" type="text" placeholder="OPT" id="otp" required/>
                                </div>}
                            </div>
                            {/* {otp.isOpen && <div className="input__fields">
                                <div className="input__group d-flex align-items-end w-100">
                                    <button className="btn-1" type="submit">Submit</button> 
                                </div>
                            </div>} */}
                            {otp.verified && <div>
                                <div className="input__fields">
                                    <div className="input__group">
                                        <label htmlFor="email">Your e-mail</label>
                                        <input onChange={handleInputs} value={contactData.Email} name="Email" type="email" placeholder="example@gmail.com" id="email"/>
                                    </div>
                                    {/* <div className="input__group">
                                        <label htmlFor="phone">Phone number</label>
                                        <input onChange={handleInputs} value={contactData.RegMob1} name="RegMob1" type="text" placeholder="xxxxxxxxxx" id="phone" required/>
                                    </div> */}
                                </div>
                                <div className="extra__fields">
                                    <h4 className="heading-4 heading-4--dark">Please enter your message and full Address.</h4>
                                    <div className="input__group">
                                        <label htmlFor="Subject">Subject</label>
                                        <input onChange={handleInputs} value={contactData.Subject} name="Subject" type="text" placeholder="Subject" id="Subject"/>
                                    </div>
                                </div>
                                <div className="text__area">
                                    <div className="input__group">
                                        <label htmlFor="message">Your Message</label>
                                        <textarea onChange={handleInputs} value={contactData.Remarks} name="Remarks" id="message" rows="1" placeholder="Write your message here" required></textarea>
                                    </div>
                                </div>
                            </div>}
                            <button className="btn-1 mt-5" type="submit">{otp.verified ? 'Send Message' : 'Submit'}</button> 
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

const mapStateToContact = (state) => {
    return { };
}

export default connect(mapStateToContact, {loaderAction})(Contact);