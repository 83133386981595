
export const isMobileAction = (status) => {
    return {
        type: process.env.REACT_APP_IS_MOBILE,
        value: status
    }
}

export const loginAction = (status) => {
  return {
    type: process.env.REACT_APP_LOGIN,
    value: status
  }
}

export const userAction = (task, item={}) => {
  return {
    type: process.env.REACT_APP_USER_INFO,
    payload: {
      task: task,
      value: item
    }
  }
}

export const modalAction = (modalName, status) => {
  return {
    type: process.env.REACT_APP_MODAL,
    payload: {
      name: modalName,
      status: status
    }
  }
}

export const loaderAction = (status) => {
  return {
    type: 'LOADING',
    value: status
  }
}