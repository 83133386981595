import { useParams } from "react-router-dom";
import { blogs, colors } from "../utils/utilities";
import { HashLink } from "react-router-hash-link";

const Blog = () => {

    const params = useParams();

    const blog = blogs.find(i => i.id === parseInt(params.id));
    console.log(blog);

    return (
        <main className="blogs blog">
            <section className="blog__sec blog__main container">
                <nav aria-label="breadcrumb" className="d-none d-lg-block">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><HashLink to="/#top">Home</HashLink></li>
                        <li className="breadcrumb-item"><HashLink to="/blogs/#top">Blogs & News</HashLink></li>
                        <li className="breadcrumb-item">{blog.heading}</li>
                    </ol>
                </nav>
                <div className="row g-5">
                    <div className="col-lg-6">
                        <div className="blog__img">
                            <img src={`/assets/img/blogs/${blog.img}.png`} className="img-fluid" alt="main blog" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="main__blog">
                            <div className="blog__content">
                                <h4 className="text-2"><span><i className='bx bxs-edit-alt me-2'></i> {blog.author}</span> <span><i className='bx bxs-calendar me-2'></i> {blog.date}</span></h4>
                                <h3 className="heading-5">{blog.heading}</h3>
                                <p className="text-1">I am pleased to be working for such a high caliber IT Company. I am proud to say I learn from Versicle Institute of TechnologyIn SAP interest calculation are of 2 types 1. balance interest calulation 2 item interest calulation A system predetremined code is there for balance interest calulation it is "s" and for item interest calulation it is "p" . Balance interest calulation is done to calculate interest in G/L account. where as item interest calulation is used to calulate interest for customer and vendor. Code of interest in (ob46)</p>
                                <div className="tags-box">
                                    {blog.tags.map((tag, n) => (<span key={tag} style={{'--clr': colors[n]}}>{tag}</span>))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs__sec blogs__recent blogs__latest container">
                <h2 className="heading-5">Most Visited Blog Posts.</h2>
                <div className="latest__container row g-5">
                    {blogs.map(i => (
                        <div className="col-lg-4" key={i.img}>
                            <div className="main__blog">
                                <div className="blog__img">
                                    <img src={`/assets/img/blogs/${i.img}.png`} className="img-fluid" alt="main blog" />
                                </div>
                                <div className="blog__content">
                                    <h4 className="text-2"><span><i className='bx bxs-edit-alt me-2'></i> {i.author}</span> <span><i className='bx bxs-calendar me-2'></i> {i.date}</span></h4>
                                    <HashLink to={`/blogs/${i.id}/#top`}><h3 className="heading-5">{i.heading} </h3></HashLink>
                                    <p className="text-1">{i.content}</p>
                                    <div className="tags-box">
                                        {i.tags.map((tag, n) => (<span key={tag} style={{'--clr': colors[n]}}>{tag}</span>))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </main>
    )
}

export default Blog;