import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';                 // using <Hashlink/> to scroll between page section which is not possible with simple <Link/> 
import { modalAction } from '../actions';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { logOut } from './utils/utilities';

const Header = ({ modals, modalAction, user, isLoggedIn }) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [active, setActive] = useState('Home');                    // tracks currently active link.

    useEffect(() => {
        setActive(location.hash);
    },[location])

    return (
        <>
        <header>
            <nav className='container'>
                <div className="d-flex justify-content-between align-items-center position-relative">
                    <h3 className="navbar-brand mb-0">
                        <Link to="/"><img className='logo' src='assets/img/versicle-logo.png' alt="logo"/></Link>
                    </h3>
                    <ul id="topNav" className="navbar-nav mt-4 mt-md-0 flex-md-row align-items-center d-none d-lg-flex">
                        <li className="nav-item">
                            <HashLink className={`nav-link ${(active === '#hero' || active === '') && 'active'}`} to="/#hero">Home</HashLink>
                        </li>
                        <li className="nav-item mt-2 mt-md-0">
                            <HashLink className={`nav-link ${active === '#recomended-courses' && 'active'}`} to="/#recomended-courses">Courses</HashLink>
                        </li>
                        <li className="nav-item mt-2 mt-md-0">
                            <HashLink className={`nav-link ${active === '#blogs' && 'active'}`} to="/#blogs">Blog</HashLink>
                        </li>
                        <li className="nav-item mt-2 mt-md-0">
                            <HashLink className={`nav-link ${active === '#contact' && 'active'}`} to="/#contact">Contact</HashLink>
                        </li>
                        <li className="nav-item mt-2 mt-md-0">
                            <HashLink className={`nav-link ${active === '#course-overview' && 'active'}`} to="/#course-overview">Services</HashLink>
                        </li>
                        <li className="nav-item mt-2 mt-md-0">
                            <HashLink className={`nav-link ${active === '#mockTest' && 'active'}`} to="#">Mock Test</HashLink>
                        </li>
                    </ul>
                    <div className="d-flex gap-3 gap-md-4 align-items-center">
                        {isLoggedIn && <div className='dropdown has-arrow logged-item'>
                            <Link to="#" className="dropdown-toggle nav-link align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="user-img">
                                    <img className="rounded-circle" src="/assets/img/user_unknown.png" width="31" alt={user.UserFullName}/>
                                </span>
                                <h6 className="mb-0">{user.UserFullName}</h6>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-right">
                                {/* <Link className="dropdown-item" to="/profile">
                                    <i className='bx bx-user-circle h3 mb-0 me-2'></i> Profile
                                </Link>
                                <Link className="dropdown-item" to="/dashboard">
                                    <i className='bx bx-tachometer h3 mb-0 me-2'></i> Dashboard
                                </Link> */}
                                <span role='button' onClick={() => logOut(navigate)} className="dropdown-item">
                                    <i className='bx bx-log-out-circle h3 mb-0 me-2'></i> Logout
                                </span>
                            </div>
                        </div>}

                        {!isLoggedIn && <button type="button" className='btn header-btn login-btn' onClick={() => modalAction('LOGIN_MODAL', true)}>Login</button>}
                        {!isLoggedIn && <button type="button" className='btn header-btn' onClick={() => modalAction('ENROLL_MODAL', true)}>Enroll now</button>}
                        <svg onClick={() => modalAction('SIDE_MENU', true)} className={`menu-icon d-lg-none ${modals.SIDE_MENU && 'active'}`} width="25" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line id='middle-line' y1="9.5" x2="25" y2="9.5" stroke="#2BB4CA" strokeWidth="3"/>
                            <line id='top-line' y1="1.5" x2="25" y2="1.5" stroke="#2BB4CA" strokeWidth="3"/>
                            <line id='bottom-line' y1="17.5" x2="25" y2="17.5" stroke="#2BB4CA" strokeWidth="3"/>
                        </svg>
                    </div>
                </div>
            </nav>
        </header>
        
        
        
        </>
    )
}

const mapStateToHeader = (state) => {
    return { modals: state.modals, user: state.user, isLoggedIn: state.isLoggedIn };
}

export default connect(mapStateToHeader, {modalAction})(Header);


// const MobileMenu = () => {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     return (
//         <>
//         <Button variant="primary" onClick={handleShow}>
//             Launch
//         </Button>

//         <Offcanvas show={show} onHide={handleClose}>
//             <Offcanvas.Header closeButton>
//             <Offcanvas.Title>Offcanvas</Offcanvas.Title>
//             </Offcanvas.Header>
//             <Offcanvas.Body>
//             Some text as placeholder. In real life you can have the elements you
//             have chosen. Like, text, images, lists, etc.
//             </Offcanvas.Body>
//         </Offcanvas>
//         </>
//     );
// }

