import { connect } from "react-redux";
import { decrypt } from "./utilities";
import { useEffect } from "react";
import { loaderAction, loginAction, userAction } from "../../actions";
import axios from "axios";

const Auth = ({ loaderAction, loginAction, userAction }) => {

    useEffect(() => {
        function autoLogin() {
            const savedLoginData = decrypt(localStorage.getItem('vitLoginData'));
            console.log(savedLoginData);
            if (savedLoginData && savedLoginData.RegMob1) {
                console.log('Logging you in! Please wait.');        
                makeLoginRequest({ RegMob1: savedLoginData.RegMob1, UserPassword: savedLoginData.UserPassword });
            } 
        }
        autoLogin();
    },[])

    const makeLoginRequest = async (params) => {
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth?UN=${params.RegMob1}&UP=${params.UserPassword}&CID=W4kjtsE1rUyGzbv6rJqnNw==`);
        loaderAction(false);
        if (res.data.UserId === 0) {
            alert('Failed to Log you in. Please log in manually');
        } else {
            userAction('ADD', res.data);
            loginAction(true);
            // let userLoginData = { RegMob1: params.RegMob1, password: res.data.UserPassword };
            // localStorage.setItem("vitLoginData", encrypt(userLoginData));    
        }
    }
}

const mapStateToAuth = (state) => { 
    return { };
}

export default connect(mapStateToAuth, {loaderAction, loginAction, userAction})(Auth);