import { combineReducers  } from 'redux';



const IsMobileReducer = (isMobile=false, action) => {                       // Holds Status of viewving device.
    if (action.type === process.env.REACT_APP_IS_MOBILE) {
      return action.value;
    }
    return isMobile;
}

const loginStatusReducer = (isLoggedIn=false, action) => {                  // Holds login status of user.
  if (action.type === process.env.REACT_APP_LOGIN) {
    return action.value;
  }
  return isLoggedIn;
}

let currentUser = {
  UserFullName: '',
  RegMob1: '',
  UserPassword: ''
}

const userReducer = (state=currentUser, action) => {                      // Holds all the Informations of User.
  if (action.type === process.env.REACT_APP_USER_INFO) {                  // *** OldState === newState turns out to be true which causes components to not rerender on change of deep nested properties 
    if (action.payload.task === 'ADD') {
      let newState = Object.assign(state, action.payload.value);                // or on change of properties that are without any key on them (eg. list of objects without keys).
      return {...newState};                                               // {...newState} or _.deepClone(newState) will clone the given object/state and reproduce a new object/state where           
    } else if (action.type === 'DUMP') {                                  // oldState !== {...newState} or _.deepClone(newState) which forces every connected component to re-render with new state.          
      return currentUser;                                                 // Returning Currentuser not working. it returns current user with all the fields having details of old user.
    }
  }                                                                                                                                  
  return state;                                                           // currentuser became the state hence returning new object to overcome this.                                                                                                                                                               
}
  
const modals = { 
                  LOGIN_MODAL: false,                              // using the term modal for both modals and offcanvas
                  SIDE_MENU: false,
                  ENROLL_MODAL: false
               };                    

const modalReducer = (state=modals, action) => {    
  if (action.type === process.env.REACT_APP_MODAL) {
    return {...state, [action.payload.name]: action.payload.status};
  }    
  return state;  
}

const loaderReducer = (state=false, action) => {    
  if (action.type === 'LOADING') {
    return action.value;
  }    
  return state;  
}


// COMBINE ALL REDUCERS ----------------------------------------------------------------------------------------------------

export default combineReducers({
    isMobile: IsMobileReducer,
    isLoggedIn: loginStatusReducer,
    user: userReducer,
    modals: modalReducer,
    isLoading: loaderReducer
})