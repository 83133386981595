import Table from 'react-bootstrap/Table';
import { HashLink } from 'react-router-hash-link'; 
import { connect } from 'react-redux';  
import { useNavigate } from 'react-router-dom';

const WebDesign = ({ isMobile }) => {

    const navigate = useNavigate();

    return (
        <section id="softwareDevelopment">
            <div className="container">
                <div className="row main-bg">
                    <div className="col col-12">
                        <div className="reviews__heading px-4">
                            <h2 className="heading-secondary mb-0 text-white"> Web Designing.</h2>
                            <img src="./assets/img/web-bg.png" alt="background" />
                            <button className="btn-1 d-none d-lg-block" onClick={() => navigate(-1)}>Back to Home</button>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col col-12 col-md-3">
                        <div className="row">
                            <ul className="nav flex-column nav-pills p-0" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <HashLink to={isMobile ? '#all-tabs' : '#top'}><button type="button" id="tab-1" className="nav-link active" data-bs-toggle="tab" data-bs-target="#tab-pane-1" role="tab" aria-controls="tab-pane-1" aria-selected="true"><img className='rounded-circle' src="assets/icons/WebDesign.jpg" alt="Web Design"/> Web Design</button></HashLink>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <HashLink to={isMobile ? '#all-tabs' : '#top'}><button type="button" id="tab-2" className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-pane-2" role="tab" aria-controls="tab-pane-2" aria-selected="false"><img className='rounded-circle' src="assets/icons/WebDesign.jpg" alt="Graphic Design"/> Graphic Design</button></HashLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-12 col-md-9" id='all-tabs'>
                        <div className="tab-content">
                            <div id="tab-pane-1" className="tab-pane fade show active" role="tabpanel" aria-labelledby="tab-1">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button type="button" id="tabFade-standard" className="nav-link active" data-bs-toggle="tab" data-bs-target="#tabFade-pane-standard" role="tab" aria-controls="tabFade-pane-standard" aria-selected="true">Standard</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" id="tabFade-professional" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-pane-professional" role="tab" aria-controls="tabFade-pane-professional" aria-selected="false">Professional</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button type="button" id="tabFade-advance" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-pane-advance" role="tab" aria-controls="tabFade-pane-advance" aria-selected="false">Advance / Expert</button>
                                </li>
                            </ul>
                            <div className='tab-content'>
                                <div id="tabFade-pane-standard" className="tab-pane fade show active" role="tabpanel" aria-labelledby="tabFade-standard">
                                    <ul className="nav nav-tabs bg-white nav-sm" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button type="button" id="tabFade-1-1-standard" className="nav-link active" data-bs-toggle="tab" data-bs-target="#tabFade-1-pane-1-standard" role="tab" aria-controls="tabFade-1-pane-1-standard" aria-selected="true">Course Highlights</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button type="button" id="tabFade-1-2-standard" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-1-pane-2-standard" role="tab" aria-controls="tabFade-1-pane-2-standard" aria-selected="false">Course Contents</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content p-1">
                                        <div id="tabFade-1-pane-1-standard" className="tab-pane fade show active" role="tabpanel" aria-labelledby="tabFade-1-1-standard">
                                            <h2 className="heading-tertiary mt-3 mb-4">Standard Course</h2>
                                            <Table striped borderless hover>
                                                <tbody>
                                                    <tr>
                                                        <td>Course Duration</td>
                                                        <td>48 Hours</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Course Fees</td>
                                                        <td>Coming soon.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Attendance</td>
                                                        <td>75%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Placement Assistance</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div id="tabFade-1-pane-2-standard" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-1-2-standard">
                                            <ul className="nav nav-tabs bg-white nav-sm" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button type="button" id="tabFade-standard-html" className="nav-link active" data-bs-toggle="tab" data-bs-target="#tabFade-pane-standard-html" role="tab" aria-controls="tabFade-pane-standard-html" aria-selected="true">HTML</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button type="button" id="tabFade-standard-css" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-pane-standard-css" role="tab" aria-controls="tabFade-pane-standard-css" aria-selected="false">CSS</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button type="button" id="tabFade-standard-javascript" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-pane-standard-javascript" role="tab" aria-controls="tabFade-pane-standard-javascript" aria-selected="false">Javascript</button>
                                                </li>
                                            </ul>
                                            <div className="tab-content p-1">
                                                <div id="tabFade-pane-standard-html" className="tab-pane fade show active" role="tabpanel" aria-labelledby="tabFade-standard-html">
                                                    <Table striped borderless hover>
                                                        <tbody>
                                                            {/* <tr>
                                                                <td colSpan={2} style={{fontSize: '1.1em', textAlign: 'center'}}>
                                                                    HTML5
                                                                </td>
                                                            </tr> */}
                                                            <tr>
                                                                <td colSpan={2}>Introduction To HTML5</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Introduction and Advantages of HTML5</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>First HTML5 Document</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Overview of New Features of HTMLS</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Page Layout Semantic Elements</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Header</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Navigation</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Section & Articles</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Footer</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Aside and more</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>New Attributes in input Tag</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Special html Entities</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Coordinates</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Drawing Lines, Rectangles and Circles</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Text and Font</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Color Gradiations</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Drawing Images</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Scalable Vector Graphics (SVG)</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Understanding SVG</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Benefits of SVG</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>Using SVG Tag</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div id="tabFade-pane-standard-css" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-standard-css">
                                                    <Table striped borderless hover>
                                                        <tbody>
                                                            {/* <tr>
                                                                <td colSpan={2} style={{fontSize: '1.1em', textAlign: 'center'}}>
                                                                    CSS3
                                                                </td>
                                                            </tr> */}
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Working With CSS3
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Introduction to CSS3
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Selectors
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Box Model
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    CSS3 Borders
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Working with border-radius
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Working with box-shadow
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Working with border-image
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    CSS3 Backgrounds
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Background-size
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Background-origin
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    CSS3 Gradients
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    Linear Gradients
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div id="tabFade-pane-standard-javascript" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-standard-javascript">
                                                    <Table striped borderless hover>
                                                        <tbody>
                                                            {/* <tr>
                                                                <td colSpan={2} style={{fontSize: '1.1em', textAlign: 'center'}}>
                                                                    JAVASCRIPT
                                                                </td>
                                                            </tr> */}
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Introduction and Where To find It
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JavaScript Compiler
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS HTML DOM
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Intro
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Methods
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Document
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Elements
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM HTML
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM CSS
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Animations
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Events
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Event Listener
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Navigation
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Nodes
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Collections
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    DOM Node Lists
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Window
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Popup Alert
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Statements
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Syntax
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Comments
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Variables
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Operators
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Arithmetic
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Assignment
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Data Types
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    JS Functions
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div id="tabFade-pane-professional" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-professional">
                                    <ul className="nav nav-tabs bg-white nav-sm" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button type="button" id="tabFade-1-1-professional" className="nav-link active" data-bs-toggle="tab" data-bs-target="#tabFade-1-pane-1-professional" role="tab" aria-controls="tabFade-1-pane-1-professional" aria-selected="true">Course Highlights</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button type="button" id="tabFade-1-2-professional" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-1-pane-2-professional" role="tab" aria-controls="tabFade-1-pane-2-professional" aria-selected="false">Course Contents</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content p-1">
                                        <div id="tabFade-1-pane-1-professional" className="tab-pane fade show active" role="tabpanel" aria-labelledby="tabFade-1-1-professional">
                                            <h2 className="heading-tertiary mt-3 mb-4">Professional Course</h2>
                                            <Table striped borderless hover>
                                                <tbody>
                                                    <tr>
                                                        <td>Course Duration</td>
                                                        <td>48 Hours</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Course Fees</td>
                                                        <td>Coming soon.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Attendance</td>
                                                        <td>75%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Placement Assistance</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div id="tabFade-1-pane-2-professional" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-1-2-professional">
                                                <ul className="nav nav-tabs bg-white nav-sm" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button type="button" id="tabFade-professional-html" className="nav-link active" data-bs-toggle="tab" data-bs-target="#tabFade-pane-professional-html" role="tab" aria-controls="tabFade-pane-professional-html" aria-selected="true">HTML</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button type="button" id="tabFade-professional-css" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-pane-professional-css" role="tab" aria-controls="tabFade-pane-professional-css" aria-selected="false">CSS</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button type="button" id="tabFade-professional-javascript" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-pane-professional-javascript" role="tab" aria-controls="tabFade-pane-professional-javascript" aria-selected="false">Javascript</button>
                                                    </li>
                                                </ul>
                                                <div className="tab-content p-1">
                                                    <div id="tabFade-pane-professional-html" className="tab-pane fade show active" role="tabpanel" aria-labelledby="tabFade-professional-html">
                                                        <Table striped borderless hover>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Media — Audio And Video</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Audio and Source tags</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Video and Source tags</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Browser Compatibility</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Programming using Javascript</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Form Elements And Attributes</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>New Input Types</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>New Elements in Form</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>New Attributes in Form Tag</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Introduction to Bootstrap</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Installation</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Typography</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Images</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Tables</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Figures</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Form control</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Select</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Checks & radios</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Range</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Input group</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Floating labels</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Breakpoints</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Containers</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Grid</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Columns</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Gutters</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Utilities</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Helpers</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Utilities</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div id="tabFade-pane-professional-css" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-professional-css">
                                                        <Table striped borderless hover>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Radial Gradients</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>CSS3 Text Effects</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Text</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>-shadow</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Word-wrap</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>CSS3 Web Fonts</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Creating custom fonts</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>CSS3 2D Transforms</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Working with translate</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Working with rotate</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Working with scale</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Working with skew</td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Working with matrix</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div id="tabFade-pane-professional-javascript" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-professional-javascript">
                                                        <Table striped borderless hover>
                                                            <tbody>
                                                                <tr>
                                                                    <td>JS Objects</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Scope</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Events</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Strings</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS String Methods</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Numbers</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Number Methods</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Math</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Random</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Dates</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Date Formats</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Date Methods</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Arrays</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Array Methods</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Array Sort</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Booleans</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Comparisons</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Conditions</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Switch</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Loop For</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Loop While</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Break</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Type Conversion</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Errors</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Hoisting</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Strict Mode</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Best Practices</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Reserved Words</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS JSON</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Objects</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Object Methods</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Object Constructors</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JS Functions</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Intro</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Get Started</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Syntax</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Selectors</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Events</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Hide/Show</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Fade</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Slide</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Animate</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery stop()</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>jQuery Callback</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div id="tabFade-pane-advance" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-advance">

                                    <ul className="nav nav-tabs bg-white nav-sm" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button type="button" id="tabFade-1-1-advance" className="nav-link active" data-bs-toggle="tab" data-bs-target="#tabFade-1-pane-1-advance" role="tab" aria-controls="tabFade-1-pane-1-advance" aria-selected="true">Course Highlights</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button type="button" id="tabFade-1-2-advance" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-1-pane-2-advance" role="tab" aria-controls="tabFade-1-pane-2-advance" aria-selected="false">Course Contents</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content p-1">
                                        <div id="tabFade-1-pane-1-advance" className="tab-pane fade show active" role="tabpanel" aria-labelledby="tabFade-1-1-advance">
                                            <h2 className="heading-tertiary mt-3 mb-4">Advance / Expert Course</h2>
                                            <Table striped borderless hover>
                                                <tbody>
                                                    <tr>
                                                        <td>Course Duration</td>
                                                        <td>48 Hours</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Course Fees</td>
                                                        <td>Coming soon.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Attendance</td>
                                                        <td>75%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Placement Assistance</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div id="tabFade-1-pane-2-advance" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-1-2-advance">
                                            <ul className="nav nav-tabs bg-white nav-sm" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button type="button" id="tabFade-advance-html" className="nav-link active" data-bs-toggle="tab" data-bs-target="#tabFade-pane-advance-html" role="tab" aria-controls="tabFade-pane-advance-html" aria-selected="true">HTML</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button type="button" id="tabFade-advance-css" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-pane-advance-css" role="tab" aria-controls="tabFade-pane-advance-css" aria-selected="false">CSS</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button type="button" id="tabFade-advance-javascript" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-pane-advance-javascript" role="tab" aria-controls="tabFade-pane-advance-javascript" aria-selected="false">Javascript</button>
                                                </li>
                                            </ul>
                                            <div className="tab-content p-1">
                                                <div id="tabFade-pane-advance-html" className="tab-pane fade show active" role="tabpanel" aria-labelledby="tabFade-advance-html">
                                                    <Table striped borderless hover>
                                                        <tbody>
                                                            <tr>
                                                                <td>Bootstrap Overview</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Validation</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Accordion</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Alerts</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Badge</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Breadcrumb</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Buttons</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Button group</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Card</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Carousel</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Close button</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Collapse</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Dropdowns</td>
                                                            </tr>
                                                            <tr>
                                                                <td>List group</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Modal</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Navs & tabs</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Navbar</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Offcanvas</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Pagination</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Popovers</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Progress</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Scrollspy</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Spinners</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Toasts</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tooltips</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Drag And Drop</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Drag and Drop Events</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Web Storage</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Local Storage</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Session Storage</td>
                                                            </tr>
                                                            <tr>
                                                                <td>HTML Geolocation</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div id="tabFade-pane-advance-css" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-advance-css">
                                                    <Table striped borderless hover>
                                                        <tbody>
                                                            <tr>
                                                                <td>CSS3 3D Transforms</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Working with rotateX</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Working with rotateY</td>
                                                            </tr>
                                                            <tr>
                                                                <td>CSS3 Transitions And Animations</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Working with Animations</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Working with Transitions</td>
                                                            </tr>                                                
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div id="tabFade-pane-advance-javascript" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-advance-javascript">
                                                    <Table striped borderless hover>
                                                        <tbody>
                                                            <tr>
                                                                <td>Function Call</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Function Apply</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Function Closures</td>
                                                            </tr>
                                                            <tr>
                                                                <td>JSON Intro</td>
                                                            </tr>
                                                            <tr>
                                                                <td>JSON Objects</td>
                                                            </tr>
                                                            <tr>
                                                                <td>JSON Arrays</td>
                                                            </tr>
                                                            <tr>
                                                                <td>JSON Parse</td>
                                                            </tr>
                                                            <tr>
                                                                <td>JSON Stringify</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Execution of Function Code</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Scope and Execution Example</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lexical Scope</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Cheating Lexical Scope: eval</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Block Scope in ES6</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Problems with the Let Keyword</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hoisting</td>
                                                            </tr>
                                                            <tr>
                                                                <td>this Keyword</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Binding Confusion</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Explicit Binding</td>
                                                            </tr>
                                                            <tr>
                                                                <td>The New keyword</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Closures</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Closure Examples</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Module Patterns</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Async Patterns</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Callbacks</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Solving Callback Problems</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Promises</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Chaining</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Get</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Set</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Add</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Remove</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery CSS Classes</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery css()</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Dimensions</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Traversing</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Ancestors</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Descendants</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Siblings</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Filtering</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery AJAX Intro</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Load</td>
                                                            </tr>
                                                            <tr>
                                                                <td>jQuery Get/Post</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div id="tab-pane-2" className="tab-pane fade" role="tabpanel" aria-labelledby="tab-2">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button type="button" id="tabFade-2-1" className="nav-link active" data-bs-toggle="tab" data-bs-target="#tabFade-2-pane-1" role="tab" aria-controls="tabFade-2-pane-1" aria-selected="false">Course Highlights</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button type="button" id="tabFade-2-2" className="nav-link" data-bs-toggle="tab" data-bs-target="#tabFade-2-pane-2" role="tab" aria-controls="tabFade-2-pane-2" aria-selected="false">Course Details</button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div id="tabFade-2-pane-1" className="tab-pane fade show active" role="tabpanel" aria-labelledby="tabFade-2-1">
                                    <h2 className="heading-tertiary mt-3 mb-4">Graphics Design</h2>
                                        <Table striped borderless hover>
                                            <tbody>
                                                <tr>
                                                    <td>Course Duration</td>
                                                    <td>12 Weeks</td>
                                                </tr>
                                                <tr>
                                                    <td>Course Intake</td>
                                                    <td>4 to 5</td>
                                                </tr>
                                                <tr>
                                                    <td>Course Fees</td>
                                                    <td>12000/-</td>
                                                </tr>
                                                <tr>
                                                    <td>Attendance</td>
                                                    <td>75%</td>
                                                </tr>
                                                <tr>
                                                    <td>Placement Assistance</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div id="tabFade-2-pane-2" className="tab-pane fade" role="tabpanel" aria-labelledby="tabFade-2-2">
                                        <ul className='details-list'>
                                            <li>
                                                <h2 className="heading-tertiary">INTRODUCTION</h2>
                                                <img className='float-start mb-4 me-5 img-fluid' style={{maxWidth: '34rem'}} src="assets/img/GraphicDesign.jpg" alt="GraphicDesign"/>
                                                <p className='mb-4'>Graphic design is a resourceful process used for boosting and propelling the image of various associations. The internet has made elite utilization of graphics to set up new websites and designs on the internet. With graphics we have necessary relief between pages of text and make our website more fascinating as beautiful graphics are always attention grabbers. Creative and high-quality graphics have the power to attract and retain customers for longer.</p>
                                                <p className='mb-4'>The business industry makes use of graphic design to build business tools and entertainment industry uses it to ornate the production with graphical metaphors and 3D images. Some of graphic designing common uses include identity, product packaging, promotions and advertisement campaigns. It is used in developing logos, branding, business cards and brochures. Publication industry makes good use of it in their magazines, newspapers and also in books.</p>
                                                <p className='mb-4'>To influence business growth and turnovers we use graphic designing to represent brands and create durable corporate identities. Graphic design can be implemented by using templates and pre-designed layouts. This approach is usually implemented by the designers that can't pay much time in designing the given assignment from scratch.</p>
                                                <p>Thus, a quality graphic design is easily appreciable and has high attractive, open and retentive value. At times graphics are more proficient of drawing interest and targeted traffic.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn-1 d-lg-none mt-5 ms-auto w-auto" onClick={() => navigate(-1)}>Back to Home</button>
                </div>
            </div>
        </section>
    )
}

const mapStateToWebDesign = (state) => {
    return { isMobile: state.isMobile };
}

export default connect(mapStateToWebDesign, {})(WebDesign);