import { modalAction } from "../actions";
import { OffcanvasComponent } from "./utils/utilities";
import LoginModal from "./modals/loginModal";
import EnrollModal from "./modals/enrollModal";
import { connect } from "react-redux";
import { SidePanel } from "./sidePanel";

const Modals = ({ modals, modalAction }) => {

    // using the term modal for both modals and offcanvas.

    return (
        <>
            {/* <ModalComponent isActive={modals.LOGIN_MODAL} child={<LoginModal handleModal={modalAction}/>}/> */}                             
            {/* <ModalComponent isActive={modalActive} child={<TestComponent handleModal={setModalActive}/>}/>   */}                                    

            <OffcanvasComponent isActive={modals.SIDE_MENU} child={<SidePanel handleClose={modalAction} />} />                                                    
            {/* <OffcanvasComponent isActive={modals.LOGIN_MODAL} child={LoginModal({handleClose: modalAction, modals: modals})} placement='end' className="login-modal"/> */}
            <OffcanvasComponent isActive={modals.LOGIN_MODAL} child={<LoginModal handleClose={modalAction} modals={modals} />} placement='end' className="login-modal"/> 
            <OffcanvasComponent isActive={modals.ENROLL_MODAL} child={<EnrollModal handleClose={modalAction} modals={modals} />} placement='end' className="login-modal"/> 
        </>
    )
}

const mapStateToModals = (state) => {
    return { modals: state.modals };
}

export default connect(mapStateToModals, {modalAction})(Modals);

// -----------------------------------------------------------------------------------------------------------------------

// ⭐⭐⭐ We can pass child using 2 methods both works fine but there is 2 (found till day) major difference between them.
// Method 1 - Passing child as function - child={LoginModal({handleClose: modalAction, modals: modals})} - Using this method we can pass both function (loginModal()) and the React Components (except redux connected components, see below) (<LoginModal />).
// Method 2 - Passing child as React component child={<LoginModal handleClose={modalAction} modals={modals} /> - Using this method we can only pass React components.
// Difference - in method 1 child is passed as a function means it will be treated as a function. hence the concept of mount and unmount (except initial mount) doesn't works on them. this makes clear that if we use useEffect in them then,
//              it will not identify the unmount event and will not rerender the component when it is re-mounted. empty dependency array will not work as expected. for example if we pass Login modal with method 1 then opening the form
//              triggers the mount event but closing it doesn't triggers the unmount event hence re-mount event also never happens, resulting the LoginModal to never reset itself on further openings.
//              ⭐⭐⭐Also note that Redux connected components also cannot get passed using this method. doing so will throw weird errors saying item is not a function.
//
//              But in the case of method 2 we can pass both normal and redux connected React components but functions are not allowed. unlike method 1, mount and unmount events works perfectly on this case. Using useEffect 
//              with empty dependency array works fine and Component is reset to it's initial state on each mount and unmount events.
//
//
//
//




