// import { HashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import { encrypt, handleNumberInputs, getOtp } from "../utils/utilities";

import { useEffect, useState } from "react";
import { loginAction, userAction, loaderAction } from "../../actions";
import axios from "axios";

const LoginModal = ({ handleClose, isLoggedIn, loginAction, userAction, user, loaderAction }) => {

    const [tabActive, setTabActive] = useState('login');
    const [otp, setOTP] = useState({isOpen: false, recievedOTP: 'zslfjsd', enteredOTP: '', sent: false, verified: false });
    const [loginData, setLoginData] = useState({ RegMob1: '', UserPassword: '' });
    const [regData, setRegData] = useState({ Name: '', RegMob1: '', UserPassword: '', UserType: 'CUSTOMER', Email: '', Pin: '', Address: '', EncCompanyId: 'W4kjtsE1rUyGzbv6rJqnNw==' });
    const [recoveryNumber, setRecoveryNumber] = useState('');
    const [error, setError] = useState({ status: false, message: ''});
    const [forgotPasswordError, setForgotPasswordError] = useState({ status: false, message: "We'll send your password !"});

    useEffect(() => {                                                             // mount/unmount working. page resets automatically on re-mount. notes (4).
        if (isLoggedIn) {
            setLoginData({ RegMob1: user.RegMob1, UserPassword: user.UserPassword });
            setRegData({ Name: user.UserFullName, RegMob1: user.RegMob1, UserPassword: '' });
            setOTP(pre => ({ ...pre, verified: true }));
        } else {
            setLoginData({ RegMob1: '', UserPassword: '' });
            setRegData({ Name: '', RegMob1: '', UserPassword: '', UserType: 'CUSTOMER', Email: '', Pin: '', Address: '', EncCompanyId: 'W4kjtsE1rUyGzbv6rJqnNw==' });
            setOTP({ isOpen: false, recievedOTP: null, enteredOTP: '', sent: false, verified: false });
        }
    },[isLoggedIn, user]);

    const handleLoginForm = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    }

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        if (isLoggedIn) return;
        if (loginData.RegMob1.length < 10 || loginData.UserPassword.length < 4) return alert('Enter valid phone number and a password of at least 4 characters.');
        makeLoginRequest(loginData)
    }

    const makeLoginRequest = async (params) => {
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth?UN=${params.RegMob1}&UP=${params.UserPassword}&CID=W4kjtsE1rUyGzbv6rJqnNw==`);
        loaderAction(false);
        if (res.data.UserId === 0) {
            setError({ status: true, message: 'The username or password is incorrect.' });
        } else {
            console.log(res.data);
            userAction('ADD', res.data);
            loginAction(true);
            handleClose('LOGIN_MODAL', false); 
            let userLoginData = { RegMob1: params.RegMob1, UserPassword: res.data.UserPassword };
            localStorage.setItem("vitLoginData", encrypt(userLoginData));    
        }
    }

    const handleRegForm = (e) => {
        const { name, value } = e.target;
        setRegData({ ...regData, [name]: value });
    }

    const handleRegFormSubmit = async (e) => {
        console.log(regData);
        e.preventDefault();
        if (isLoggedIn) return;
        if (regData.RegMob1.length < 10 || regData.UserPassword.length < 4) return alert('Enter valid phone number and a password of at least 4 characters.');
        const doesUserExist = await checkExistingUser(regData.RegMob1);
        if (doesUserExist) return;
        if (!otp.isOpen) {
            loaderAction(true);
            let recievedOpt = await getOtp(regData.Name, regData.RegMob1);
            loaderAction(false);
            if (!recievedOpt) return alert('An error occured. failed to send otp.');
            setOTP({...otp, isOpen: true, sent: true, recievedOTP: recievedOpt});
        } 
        if (otp.sent) {
            if (otp.recievedOTP !== otp.enteredOTP) return alert('Incorrect OTP.');
            let status = await makeRegisterationRequest(regData);
            if (status) {
                setOTP({ ...otp, verified: true });
                loginAction(true);
                handleClose('LOGIN_MODAL', false);     
            } else {
                alert('An Error Occured, Please try again later.');
            }
        }
    }

    const makeRegisterationRequest = async (params) => {
        console.log(params);
        if (!params.UserType) return alert('Error, no user type received.');
        try {
            loaderAction(true);
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/UserReg`, params);
            loaderAction(false);
            if (res.data[0] === 'Y') {
                localStorage.setItem("vitLoginData", encrypt({ RegMob1: params.RegMob1, UserPassword: params.UserPassword }));
                userAction('ADD', {...params, UserFullName: params.Name, UserId: parseInt(res.data[1])});            // received UserId is string type hence converting it to integer because everywhere (received login data) else it's used as integer
                return true;                                                              // 'UserId' !== UserId which can cuase wrong output in filtering just like done in getMembersList function.
            }      
        } catch (err) {
            loaderAction(false);
            console.log(err);
            return false;
        }   
    }

    const checkExistingUser = async (phoneNumber) => {
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserReg?UN=${phoneNumber}`);
        loaderAction(false);
        if (res.data === 'Y') {
            setError({status: true, message: 'This number is already registered.'});
            setLoginData(pre => ({ ...pre, RegMob1: phoneNumber }));
            setRegData(pre => ({ ...pre, RegMob1: '' }));
            setOTP({isOpen: false, recievedOTP: 'zslfjsd', enteredOTP: '', sent: false, verified: false });     // reset otp field if user enters a number that is already registered, this will force user to again pass otp verification.
            setTabActive('login');
            return true;
        } else {
            setError({status: false, message: ''});
            return false;
        }
    }

    const handleForgotPasswordForm = async (e) => {
        e.preventDefault();
        console.log(recoveryNumber);
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth/0?UN=${recoveryNumber}&CID=W4kjtsE1rUyGzbv6rJqnNw==&Type=FP`, {});
        loaderAction(false);
        if (res.data === 'Y') {
          setForgotPasswordError({status: true, message: 'We sent your password to your number. please check !'});
        } else {
          setForgotPasswordError({status: true, message: 'This number is not Registered.'});
        }
    }

    return (
        <section className="login h-100" style={{padding: '0.5em'}}>
            <div className="container">
                <div className="login-modal__page">
                    <i className='bx bx-chevrons-left modal-close-btn' onClick={() => handleClose('LOGIN_MODAL', false)}></i>
                    <div className="login-modal__page__left"></div>
                    <div className="login-modal__page__right">
                        <h1 className="heading-secondary">Hello There!</h1>
                        <p>We're glad having you on VIT Educare</p>
                        <div className="form-wrapper">
                            <div className="tab-content">
                                <div className={`tab-pane fade ${tabActive === 'login' && 'show active'}`} role="tabpanel" aria-labelledby="tabFade-1">
                                    <form className="login-form" onSubmit={handleLoginSubmit}>
                                        <div className="input__group">
                                            <input onChange={(e) => handleNumberInputs(e, setLoginData)} value={loginData.RegMob1} readOnly={otp.verified} autoComplete="true" type="text" maxLength={10} placeholder="Phone number" name='RegMob1' required/>
                                        </div>
                                        <div className="input__group">
                                            <input className="mb-4" onChange={handleLoginForm} value={loginData.UserPassword} readOnly={otp.verified} autoComplete="true" type="password" placeholder="Password" name='UserPassword' required/>
                                        </div>
                                        {error.status && <p style={{textAlign: 'left', color: 'var(--color-8)', margin: '0 0 0.8em 0'}}>{error.message}</p>}
                                        <p role="button" onClick={() => setTabActive('forgotPassword')}>Forgot your password ?</p>
                                        <button type="submit" className="btn-1">Login</button>
                                    </form>
                                    <div className="login-bottom">
                                        <p>Not a member ?</p>
                                        <button className="btn-1" onClick={() => setTabActive('register')}>Register Now</button>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${tabActive === 'register' && 'show active'}`} role="tabpanel" aria-labelledby="tabFade-2">
                                    <form className="register-form" onSubmit={handleRegFormSubmit}>
                                        <div className="input__group input__group--inline">
                                            <input onChange={handleRegForm} value={regData.Name} autoComplete="true" type="text" placeholder="Your name" name="Name" required />
                                            <input onChange={(e) => handleNumberInputs(e, setRegData)} value={regData.RegMob1} readOnly={otp.verified} autoComplete="true" type="text" maxLength={10} placeholder="Phone number" name="RegMob1" required/>
                                        </div>
                                        <div className="input__group input__group--inline">
                                            <input onChange={handleRegForm} value={regData.Email} autoComplete="true" type="email" placeholder="E - mail" name="Email" required />
                                            <input onChange={handleRegForm} value={regData.Address} autoComplete="true" type="text" placeholder="Address" name="Address" required/>
                                        </div>
                                        <div className="input__group input__group--inline">
                                            <input onChange={(e) => handleNumberInputs(e, setRegData)} value={regData.Pin} autoComplete="true" type="text" maxLength={6} placeholder="Pin number" name="Pin" required/>
                                            <input onChange={handleRegForm} value={regData.UserPassword} autoComplete="true" type="password" placeholder="Password" name="UserPassword" required/>
                                        </div>
                                        {otp.isOpen && <div className="input__group">
                                            <input onChange={(e) => setOTP({...otp, enteredOTP: e.target.value})} autoComplete="false" value={otp.enteredOTP} type="text" placeholder="Enter OTP"/>
                                        </div>}
                                        {!otp.isOpen && <p>You'll receive an OTP</p>}
                                        <button className="btn-1">Register</button>
                                    </form>
                                    <div className="login-bottom">
                                        <p>Already a member ?</p>
                                        <button className="btn-1" onClick={() => setTabActive('login')}>Login</button>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${tabActive === 'forgotPassword' && 'show active'}`} role="tabpanel" aria-labelledby="tabFade-1">
                                    <form onSubmit={handleForgotPasswordForm}>
                                        <div className="input__group">
                                            <input onChange={(e) => handleNumberInputs(e, setRecoveryNumber)} data-type='primitive' value={recoveryNumber} autoComplete="true" type="text" maxLength={10} placeholder="Phone number" name='recoveryNumber' required/>
                                        </div>
                                        {/* <div className="input__group">
                                            <input className="mb-4" onChange={handleLoginForm} value={loginData.password} readOnly={otp.verified} autoComplete="true" type="password" placeholder="Password" name='password' required/>
                                        </div>
                                        {error.status && <p style={{textAlign: 'left', color: 'var(--color-8)', margin: '0 0 0.8em 0'}}>{error.message}</p>} */}
                                        <p>{forgotPasswordError.message}</p>
                                        <button type="submit" className="btn-1">Get Password</button>
                                    </form>
                                    <div className="login-bottom">
                                        <p>Remember your password ?</p>
                                        <button className="btn-1" onClick={() => setTabActive('login')}>Back to Login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToLoginModal = (state) => {
    return { isLoggedIn: state.isLoggedIn, user: state.user };
}

export default connect(mapStateToLoginModal, {loginAction, userAction, loaderAction})(LoginModal);