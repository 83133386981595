// import { HashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import { encrypt, getOtp, handleNumberInputs } from "../utils/utilities";

import { useEffect, useState } from "react";
import { loaderAction, loginAction, userAction } from "../../actions";
import axios from 'axios';

const EnrollModal = ({ handleClose, isLoggedIn, loginAction, userAction, user, loaderAction }) => {

    const [otp, setOTP] = useState({isOpen: false, recievedOTP: 'zslfjsd', enteredOTP: '', sent: false, verified: false });
    const [enrollData, setEnrollData] = useState({ Name: '', RegMob1: '', UserPassword: '', UserType: 'CUSTOMER', Email: '', Pin: '', Address: '', EncCompanyId: 'W4kjtsE1rUyGzbv6rJqnNw==' });
    const [error, setError] = useState({ status: false, message: ''});

    useEffect(() => {           // mount/unmount working. page resets automatically on re-mount. notes (4).
        setEnrollData({ Name: '', RegMob1: '', UserPassword: '', UserType: 'CUSTOMER', Email: '', Pin: '', Address: '', EncCompanyId: 'W4kjtsE1rUyGzbv6rJqnNw==' });
        setOTP({ isOpen: false, recievedOTP: 'zslfjsd', enteredOTP: '', sent: false, verified: false });
    },[]);

    const handleEnrollInputs = (e) => {
        const { name, value } = e.target;
        setEnrollData({ ...enrollData, [name]: value });
    }

    const handleEnrollSubmit = async (e) => {
        e.preventDefault();
        if (enrollData.RegMob1.length < 10) return alert('Enter valid phone number.');
        const doesUserExist = await checkExistingUser(enrollData.RegMob1);
        if (doesUserExist) return;
        if (!otp.isOpen && !otp.sent) {
            loaderAction(true);
            const getOpt = await getOtp(enrollData.Name, enrollData.RegMob1);
            loaderAction(false);
            if (getOpt) setOTP({...otp, isOpen: true, sent: true, recievedOTP: getOpt});
        } else if (otp.isOpen && otp.sent) {
            if (otp.recievedOTP !== otp.enteredOTP) return alert('Incorrect OTP.');
            setOTP({ ...otp, isOpen: false, verified: true });
        } else if (otp.verified) {
            const status = await makeRegisterationRequest(enrollData);
            if (status) {
                setOTP({ ...otp, verified: true });
                loginAction(true);
                handleClose('ENROLL_MODAL', false); 
            } else {
                alert('An Error Occured, Please try again later.');
            }
        }
    }

    const makeRegisterationRequest = async (params) => {
        console.log(params);
        if (!params.UserType) return alert('Error, no user type received.');
        try {
            loaderAction(true);
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/UserReg`, params);
            loaderAction(false);
            if (res.data[0] === 'Y') {
                localStorage.setItem("vitLoginData", encrypt({ RegMob1: params.RegMob1, UserPassword: params.UserPassword }));
                userAction('ADD', {...params, UserFullName: params.Name, UserId: parseInt(res.data[1])});            // received UserId is string type hence converting it to integer because everywhere (received login data) else it's used as integer
                return true;                                                              // 'UserId' !== UserId which can cuase wrong output in filtering just like done in getMembersList function.
            }      
        } catch (err) {
            loaderAction(false);
            console.log(err);
            return false;
        } 
    }

    const checkExistingUser = async (phoneNumber) => {
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserReg?UN=${phoneNumber}`);
        loaderAction(false);
        if (res.data === 'Y') {
            setError({status: true, message: 'This number is already registered.'});
            setOTP({isOpen: false, recievedOTP: 'zslfjsd', enteredOTP: '', sent: false, verified: false });     // reset otp field if user enters a number that is already registered, this will force user to again pass otp verification.
            return true;
        } else {
            setError({status: false, message: ''});
            return false;
        }
    }

    return (
        <section className="contactUs contactUs--enrollModal" id="contact">
            <div className="container">
                <form className="contact__page position-relative" onSubmit={handleEnrollSubmit}>
                    <i className='bx bx-chevrons-left modal-close-btn' onClick={() => handleClose('ENROLL_MODAL', false)}></i>
                    <div className="contact__page__left" data-aos="fade-down" data-aos-offset="400" data-aos-duration='1000'>
                        <h2 className="heading-2">Enroll Now !</h2>
                        <p>Fill up the form and our Team will get back to you within 24 hours.</p>
                        <div className="accordion accordion-styles address_list" id="accordion-1">
                            <div className="card">
                                <div id="headingOne-1">
                                    <button className="btn-block text-left collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-1" aria-expanded="true" aria-controls="collapseOne-1">
                                        <i className='bx bxs-caret-down-circle'></i> Tally ERP & GST Training
                                    </button>
                                </div>
                                <div id="collapseOne-1" className="accordion-collapse collapse" aria-labelledby="headingOne-1" data-bs-parent="#accordion-1">
                                    <div className="card-body">
                                        Certification course in Financial Accounting, ERP softwares, Taxation and eFiling.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div id="headingTwo-1">
                                    <button className="btn-block text-left accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo-1" aria-expanded="false" aria-controls="collapseTwo-1">
                                        <i className='bx bxs-caret-down-circle'></i> Ms-Office & Computer Basics Learning
                                    </button>
                                </div>
                                <div id="collapseTwo-1" className="accordion-collapse collapse" aria-labelledby="headingTwo-1" data-bs-parent="#accordion-1">
                                    <div className="card-body">
                                        Certification course in Basic computer and handling Ms-office applications operations.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div id="headingThree-1">
                                    <button className="btn-block text-left accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-1" aria-expanded="false" aria-controls="collapseThree-1">
                                        <i className='bx bxs-caret-down-circle'></i> Web Development & UI/UX Design
                                    </button>
                                </div>
                                <div id="collapseThree-1" className="accordion-collapse collapse" aria-labelledby="headingThree-1" data-bs-parent="#accordion-1">
                                    <div className="card-body">
                                        Learn Web Development & Graphics Design with tools like Photoshop, Ilustrator and Figma.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div id="headingFour-1">
                                    <button className="btn-block text-left accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour-1" aria-expanded="true" aria-controls="collapseFour-1">
                                        <i className='bx bxs-caret-down-circle'></i> Software Development
                                    </button>
                                </div>
                            
                                <div id="collapseFour-1" className="accordion-collapse collapse" aria-labelledby="headingFour-1" data-bs-parent="#accordion-1">
                                    <div className="card-body">
                                        Software development using Java, J2EE, Asp.Net with C#, PHP, Oracle, Sql Sever, MySql etc.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="icon_links">
                            <li>
                                <i className='bx bxl-facebook'></i>
                            </li>                        
                            <li>
                                <i className='bx bxl-instagram'></i>
                            </li>                        
                            <li>
                                <i className='bx bxl-twitter' ></i>
                            </li>                        
                            <li>
                                <i className='bx bxl-linkedin'></i>
                            </li>
                        </ul>
                    </div>
                    <div className="contact__page__right">
                        <div className="contact__form">
                            <h4 id="modalVerticalTitle" className="heading-4 heading-4--dark m-0 mb-3"> <i className='bx bx-plus-circle heading-icon'></i> Enroll now.</h4>
                            <div className="input__fields">
                                <div className="input__group">
                                    <label htmlFor="userName">Your Name</label>
                                    <input name="Name" value={enrollData.Name} onChange={handleEnrollInputs} tabIndex="1" type="text" id="userName" required/>
                                </div>
                                <div className="input__group">
                                    <label htmlFor="mobileNo">Mobile Number</label>
                                    <input name="RegMob1" value={enrollData.RegMob1} onChange={(e) => handleNumberInputs(e, setEnrollData)} required placeholder="" type="text" tabIndex="1" id="mobileNo" maxLength="10"/>
                                </div>
                            </div>
                            {error.status && <p style={{color: 'var(--color-6)', fontSize: '1.4em', marginLeft: '0.8em'}}>{error.message}</p>}
                            {!otp.sent && <button id="nextBtn" className="btn-1 mt-4" type="submit" tabIndex="1">NEXT</button> }
                            {otp.isOpen && <div id="otpField" className="input__fields">
                                <div className="input__group">
                                    <label htmlFor="otpInput">Enter Your OPT</label>
                                    <input type="text" name="otp" value={otp.enteredOTP} onChange={(e) => setOTP(pre => ({ ...pre, enteredOTP: e.target.value}))} id="otpInput" autoComplete="true"/>
                                </div>
                                <div className="input__group">
                                    <button className="btn-1 mt-4" type="submit" tabIndex="1">SUBMIT OTP</button>
                                </div>
                            </div>}
                            {otp.verified && <div id="personal_fields">
                                <h4 className="heading-4 heading-4--dark m-0 mt-5"> <i className='bx bxs-user-circle heading-icon'></i> Personal Information:-</h4>
                                <div className="input__fields">
                                    <div className="input__group">
                                        <label htmlFor="userEmail">Your Email</label>
                                        <input name="Email" value={enrollData.Email} onChange={handleEnrollInputs} type="email" tabIndex="1" id="userEmail"/>
                                    </div>
                                    <div className="input__group">
                                        <label htmlFor="userAddress">Address</label>
                                        <input name="Address" value={enrollData.Address} onChange={handleEnrollInputs} required tabIndex="1" type="text" id="userAddress"/>
                                    </div>
                                </div>
                                <div className="input__fields">
                                    <div className="input__group">
                                        <label htmlFor="userPin">Pin Code</label>
                                        <input name="Pin" value={enrollData.Pin} onChange={(e) => handleNumberInputs(e, setEnrollData)} tabIndex="1" type="text" id="userPin" autoComplete="off" required maxLength="6"/>
                                    </div>
                                    <div className="input__group">
                                        <label htmlFor="userArea">Password</label>
                                        <input type="password" name="UserPassword" value={enrollData.UserPassword} onChange={handleEnrollInputs} />
                                    </div>
                                </div>
                                <button className="btn-1 mt-4" type="submit" tabIndex="1">SUBMIT</button> 
                            </div>}
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

const mapStateToEnrollModal = (state) => {
    return { isLoggedIn: state.isLoggedIn, user: state.user };
}

export default connect(mapStateToEnrollModal, {loginAction, userAction, loaderAction})(EnrollModal);