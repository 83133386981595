import { Link } from "react-router-dom";
import { blogPreviews, blogs, colors } from "../utils/utilities";
import { HashLink } from "react-router-hash-link";

const Blogs = () => {

    return (
        <main className="blogs">
            <div className="blogs__container">
                <section className="blogs__hero">
                    <div className="blogs__hero__container container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><HashLink to="/#top">Home</HashLink></li>
                                <li className="breadcrumb-item"><HashLink to="/blogs/#top">Blogs & News</HashLink></li>
                            </ol>
                        </nav>
                        <div className="heading-container">
                            <h1 className="heading-secondary">Expanding Our knowledge & yours, One blog at a time.</h1>
                            <i className='bx bxs-customize'></i>
                        </div>
                        <p>All the Latest news and events from our Team.</p>
                        <ul className="filter-list">
                            <li className="active">All <span>36</span></li>
                            <li>Development</li>
                            <li>Tally Prime</li>
                            <li>Web Technologies</li>
                            <li>Accounting</li>
                            <li>ERP Software</li>
                            <li>GST</li>
                            <li>Ms Office</li>
                            <li>
                                <input name="Name" tabIndex="1" type="text" placeholder="Search Blogs.."/>
                            </li>
                        </ul>
                    </div>
                </section>

                <section className="blogs__sec blogs__recent container" style={{borderBottom: '1px solid #cfcfcf'}}>
                    <h2 className="heading-5">Recent Blog Posts</h2>
                    <div className="recent__container row g-5">
                        <div className="recent__left col-lg-6">
                            <div className="main__blog">
                                <div className="blog__img">
                                    <img src="/assets/img/blogs/blog-1.png" className="img-fluid" alt="main blog" />
                                </div>
                                <div className="blog__content">
                                    <h4 className="text-2">Olivia Rhye * 20 Jan 2024</h4>
                                    <HashLink to="/blogs/4/#top"><h3 className="heading-5">Interest calculation in G/L account </h3></HashLink>
                                    <p className="text-1">Enhance service business success through efficient online reputation management. Explore 4 key strategies in this post for effective reputation building.</p>
                                    <div className="tags-box">
                                        <span style={{'--clr': '#ed0076'}}>Research</span>
                                        <span style={{'--clr': '#00badf'}}>Presentation</span>
                                        <span style={{'--clr': '#64c102'}}>Accounting</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="recent-right col-lg-6">
                            <div className="blogs__preview">
                                {blogs.slice(6, 12).map(i => (
                                    <div className="blogs__preview__card" key={i.img}>
                                        <div className="preview__img">
                                            <img src={`/assets/img/blogs/${i.img}.png`} className="img-fluid" alt="blog preview" />
                                        </div>
                                        <div className="preview__content">
                                            <HashLink to={`/blogs/${i.id}/#top`}><h4 className="text-2">{i.heading}</h4></HashLink>
                                            <ul className="tags-list">
                                                {i.tags.map(tag => (<li key={tag}>{tag}</li>))}
                                                <li>{i.read} mins read</li>
                                            </ul>
                                            <ul className="blog-details">
                                                <li><i className='bx bxs-edit-alt'></i> {i.author}</li>
                                                <li><i className='bx bxs-calendar'></i> {i.date}</li>
                                                <li><i className='bx bxs-comment-detail'></i> {i.comments} Comments</li>
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="blogs__sec blogs__recent blogs__latest container">
                    <h2 className="heading-5">Most Visited Blog Posts.</h2>
                    <div className="latest__container row g-5">
                        {blogs.map(i => (
                            <div className="col-lg-4" key={i.img}>
                                <div className="main__blog">
                                    <div className="blog__img">
                                        <img src={`/assets/img/blogs/${i.img}.png`} className="img-fluid" alt="main blog" />
                                    </div>
                                    <div className="blog__content">
                                        <h4 className="text-2"><span><i className='bx bxs-edit-alt me-2'></i> {i.author}</span> <span><i className='bx bxs-calendar me-2'></i> {i.date}</span></h4>
                                        <HashLink to={`/blogs/${i.id}/#top`}><h3 className="heading-5">{i.heading} </h3></HashLink>
                                        <p className="text-1">{i.content}</p>
                                        <div className="tags-box">
                                            {i.tags.map((tag, n) => (<span key={tag} style={{'--clr': colors[n]}}>{tag}</span>))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Blogs;
