import { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
    
    const [email, setEmail] = useState('');

    const handleSubmit = (e) =>  {
        e.preventDefault();
        
    }
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h3>VERSICLE INSTITUTE OF TECHNOLOGY</h3>
                            <p>
                                96L, S.P. Mukherjee Road,<br/>
                                2nd Floor, Hazra More,<br/>
                                Kolkata- 700026<br/>
                                Near Jatin Das Park Metro Station, Beside Pathfinder<br/><br/>
                                <strong>Phone:</strong> +91 8584057149/51<br/>
                                <strong>Email:</strong> info@gbooksindia.com<br/>
                            </p>
                        </div>
                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <HashLink to="/#">Home</HashLink></li>
                                {/* <li><i className="bx bx-chevron-right"></i> <Link to="./About us">About us</Link></li> */}
                                <li><i className="bx bx-chevron-right"></i> <HashLink to="/#course-overview">Services</HashLink></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/termsOfService/#">Terms of service</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/privacyPolicy/#">Privacy policy</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/faq/#">Faq</Link></li>
                                {/* <li><i className="bx bx-chevron-right"></i> <MyLink to="/sitemap" path="/sitemap.html">Sitemap</MyLink></li> */}
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Courses</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <HashLink to="/tallyPrime/#">Tally Prime</HashLink></li>
                                <li><i className="bx bx-chevron-right"></i> <HashLink to="/computerBasics/#">Computer Basics</HashLink></li>
                                <li><i className="bx bx-chevron-right"></i> <HashLink to="/webDesign/#">Web Design</HashLink></li>
                                <li><i className="bx bx-chevron-right"></i> <HashLink to="/softwareDevelopment/#">Software Development</HashLink></li>
                                <li><i className="bx bx-chevron-right"></i> <HashLink to="/returnPolicy/#">Return & Refund Policy</HashLink></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Join Our Newsletter</h4>
                            <form onSubmit={handleSubmit}>
                                <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                <input type="submit" value="Subscribe"/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright-wrap d-md-flex justify-content-between py-4">
                    <div className="mr-md-auto text-center text-md-start">
                        <div className="copyright">
                            &copy; Copyright <strong><span>GBooks</span></strong>. All Rights Reserved
                        </div>
                        <div className="credits">
                            Designed by <Link to="/">GBooks</Link>
                        </div>
                    </div>
                    <div className="social-links text-center text-md-right pt-3 pt-md-0 d-none">
                        <Link to="/" className="twitter"><i className="bx bxl-twitter"></i></Link>
                        <Link to="/" className="facebook"><i className="bx bxl-facebook"></i></Link>
                        <Link to="/" className="instagram"><i className="bx bxl-instagram"></i></Link>
                        <Link to="/" className="google-plus"><i className="bx bxl-skype"></i></Link>
                        <Link to="/" className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                    </div>
                </div>
            </div>
            {/* <div id="faq-open-btn">
                <MyLink to="/faq" path="/faq.html"><img style={{maxHeight: `3rem`}} src="/assets/img/webp-img/question-2.webp" alt="User Avatar" className="avatar-img rounded-circle" /></MyLink>
            </div> */}
            <div className={`faq-div hide`} style= {{display: 'none'}}><p>Freaquently asked questions</p></div>
        </footer>
    )
}
export default Footer;