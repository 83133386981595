import { useEffect } from "react";                      // isMobile
import { isMobileAction } from "../../actions";
import { connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';       
// import { HashLink } from "react-router-hash-link";   
import CryptoJS from 'crypto-js';

import Offcanvas from 'react-bootstrap/Offcanvas';

import Modal from 'react-bootstrap/Modal';
import axios from "axios";

const IsMobile = ({ isMobileAction }) => {                                             // Determines if device is mobile or desktop.
  
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 500px)');                      // returns object with fields like match (true/false - represents if screen width matches with max-width specified) and onChange handler.               
    isMobileAction(mediaQuery.matches);
    const handleMediaQueryChange = (e) => {
      isMobileAction(e.matches);
  }
    mediaQuery.addEventListener('change', handleMediaQueryChange);                   // run handleMediaQueryChange only when mediaQuery object which contains match (true/false) property changes.
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    }
  }, [isMobileAction])

  return;
}
  
const mapStateToIsMobile = (state) => {
  return {};
}
export const ConnectedIsMobile = connect(mapStateToIsMobile, {isMobileAction})(IsMobile);


// export function ScrollToTop({ history }) {
//   useEffect(() => {
//     const unlisten = history.listen(() => {           // listen for changes in url and scroll to top when url change is detected.
//       window.scrollTo(0, 0);
//     });
//     return () => {                                    // remove the listener before unmount.
//       unlisten();
//     }
//   }, [history]);

//   return (null);
// }  
// export default withRouter(ScrollToTop);

export const handleNumberInputs = (e, setStateName) => {
  const {name, value} = e.target;
  const dataType = e.target.attributes['data-type']?.value;               // for primitive data types.
  const re = /^[0-9\b]+$/;
  if (value === '' || re.test(value)) {
    if (dataType && dataType === 'primitive') {
      setStateName(value);
    } else {
      setStateName(pre => ({...pre, [name]: value}));
    }
  }
}

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='not-found'>
      <img src="assets/img/err-404.jpg" alt="not found"/>
      <h3 className='mt-4'>The page you are looking for couldn't be found.</h3>
      <Link to='#' onClick={() => navigate(-1)} className='btn-1'>Go Back</Link>
    </div>
  )
}

export const OffcanvasComponent = ({ isActive, child, placement='start', className=''}) => {

  return (
    <Offcanvas show={isActive} placement={placement} className={className}>                                                           {/* We'll handle open/closing from redux state hence don't need onhide here. */}
      {/* <Offcanvas.Header closeButton><Offcanvas.Title>Offcanvas</Offcanvas.Title></Offcanvas.Header> */}
      <Offcanvas.Body>
        {child}
        {/* <i className="bx bx-x-circle close-custom-modal" onClick={() => handleClose(false)}></i> */}        {/* We'll add closing button on the Child component hence don't need this. */}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export const ModalComponent = ({ isActive, child }) => {
  return (
    <Modal show={isActive} centered backdrop="static" keyboard={false}>                     {/* modals are beigng directly controlled by redux states. */}
      <Modal.Body>
        {child}
      </Modal.Body>
    </Modal>
  )
}

export const GlobalLoader = () => {
  return (
    <div className="preloader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export const encrypt = (data) => CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();

export const decrypt = (data) => {
  if (!data) return false;
  if (data.length > 200) return false;
  var bytes  = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}

export const logOut = (navigate) => {
  navigate('/');
  localStorage.removeItem('vitLoginData');
  window.location.reload();
}

export const getOtp = async (name, phone) => {
  try {
      const res = await axios.get(`https://myapps.gsterpsoft.com/api/UserReg/0`, { params: { name: name, mob: phone } });
      if (res.status === 200) {
        return res.data;
      }
  } catch (error) {
      alert('An Error Occured, Try again later.');
      return false;            
  }
}

export const blogs = [
  {
      id: 4,
      img: 'blog-1',
      heading: 'Interest calculation in G/L account',
      author: 'Shubham Sinha',
      date: '20 Jan 2024',
      content: 'Enhance service business success through efficient online reputation management. Explore 4 key strategies in this post for effective reputation building.',
      tags: ['Research', 'Presentation', 'Accounting'],
      read: 5,
      comments: 3
  },
  {
      id: 5,
      img: 'blog-2',
      heading: '5 Must Have Insights in Your Dashboard',
      author: 'Gauri Sachan',
      date: 'Feb-28-2024',
      content: 'Elevate your strategy with TallyPrime dashboard insights. Compare, track trends, manage finances, & enhance decision-making for sustained success.',
      tags: ['Research', 'Presentation', 'Accounting'],
      read: 5,
      comments: 3
  },
  {
      id: 6,
      img: 'blog-3',
      heading: 'Unlocking Business Insights: TallyPrime’s Intuitive and Powerful Dashboard',
      author: 'Tuhin Sengupta',
      date: 'Jan-29-2024',
      content: "Tailor TallyPrime's dynamic dashboard to your business needs. Explore flexibility for personalized insights. Unlock Tally's New Dashboard now",
      tags: ['Research', 'Presentation', 'Accounting'],
      read: 5,
      comments: 3
  },
  {
      id: 7,
      img: 'blog-4',
      heading: 'TallyPrime with WhatsApp for Instant and Secure Communication',
      author: 'Durgesh Bijani',
      date: 'Jan-09-2024',
      content: 'TallyPrime with WhatsApp enables a seamless communication with your customers, supplieres etc. . Embrace the future of business communication today!',
      tags: ['Research', 'Presentation', 'Accounting'],
      read: 5,
      comments: 3
  },
  {
      id: 8,
      img: 'blog-5',
      heading: 'Import Data from Excel to TallyPrime',
      author: 'Tally Solutions',
      date: 'Dec-12-2023',
      content: 'Goodbye manual entry, hello streamlined accounting! Explore the new Excel import feature in TallyPrime 4.0 for accurate & efficient process. Learn more!',
      tags: ['Research', 'Presentation', 'Accounting'],
      read: 5,
      comments: 3
  },
  {
      id: 9,
      img: 'blog-6',
      heading: 'e-Invoicing FAQs (Frequently Asked Questions)',
      author: 'RadhaKrishna M',
      date: 'Sep-14-2023',
      content: 'Explore comprehensive e-Invoicing FAQs and get answers to common questions, simplify processes, & stay informed. Your guide to seamless e-invoicing.',
      tags: ['Research', 'Presentation', 'Accounting'],
      read: 5,
      comments: 3
  },
  {
      id: 10,
      img: 'preview-1',
      heading: 'Looking for an Affordable Business Management',
      author: 'Narasimhan D R',
      date: 'Aug-02-2023',
      content: 'Enhance service business success through efficient online reputation management. Explore 4 key strategies in this post for effective reputation building.',
      tags: ['Management', 'ERP', 'Cloud based'],
      read: 6,
      comments: 3,
  },
  {
      id: 11,
      img: 'preview-2',
      heading: 'Try the Best e-Invoicing Software for Free',
      author: 'Yarab A',
      date: 'Jul-13-2023',
      content: 'Enhance service business success through efficient online reputation management. Explore 4 key strategies in this post for effective reputation building.',
      tags: ['E-Invoice Software', 'Web Technology'],
      read: 10,
      comments: 3,
  },
  {
      id: 12,
      img: 'preview-3',
      heading: 'Integration With Payment Gateways For Faster Payments',
      author: 'Durgesh Bijani',
      date: 'Oct-02-2023',
      content: 'Enhance service business success through efficient online reputation management. Explore 4 key strategies in this post for effective reputation building.',
      tags: ['Payments', 'Technology'],
      read: 5,
      comments: 3,
  },
]

export const colors = ['#ed0076', '#00badf', '#64c102', '#ed0076'];

